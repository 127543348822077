"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _Avatar = _interopRequireDefault(require("./Avatar"));

var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default,
    Avatar: _Avatar.default
  },
  props: ['network'],
  data: function data() {
    return {
      is_usermenu_open: false
    };
  },
  computed: {
    networkName: function networkName() {
      var name = TextFormatting.t('no_network');

      if (this.network) {
        name = this.network.name;
      }

      return name;
    },
    getUser: function getUser() {
      return this.network && this.network.currentUser() ? this.network.currentUser() : null;
    },
    isConnected: function isConnected() {
      return this.network && this.network.state === 'connected';
    }
  },
  methods: {
    clickForget: function clickForget() {
      var msg = 'This will delete all stored networks and start fresh. Are you sure?';
      /* eslint-disable no-restricted-globals, no-alert */

      var confirmed = confirm(msg);

      if (!confirmed) {
        return;
      }

      this.$state.persistence.forgetState();
      window.location.reload();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["StateBrowserUsermenu"]
window._kiwi_exports.components.StateBrowserUsermenu = exports.default ? exports.default : exports;
