"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _create = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/create"));

var _NetworkProvider = _interopRequireDefault(require("@/libs/NetworkProvider"));

var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));

var _StateBrowserNetwork = _interopRequireDefault(require("./StateBrowserNetwork"));

var _StateBrowserUsermenu = _interopRequireDefault(require("./StateBrowserUsermenu"));

var _AppSettings = _interopRequireDefault(require("./AppSettings"));

var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));

var netProv = new _NetworkProvider.default();
var _default = {
  components: {
    BufferSettings: _BufferSettings.default,
    StateBrowserNetwork: _StateBrowserNetwork.default,
    StateBrowserUsermenu: _StateBrowserUsermenu.default
  },
  props: ['networks', 'sidebarState'],
  data: function data() {
    return {
      show_provided_networks: false,
      provided_networks: (0, _create.default)(null),
      pluginUiElements: _GlobalApi.default.singleton().stateBrowserPlugins
    };
  },
  computed: {
    getNetwork: function getNetwork() {
      return this.$state.getActiveNetwork();
    },
    isPersistingState: function isPersistingState() {
      return !!this.$state.persistence;
    },
    isRestrictedServer: function isRestrictedServer() {
      return !!this.$state.settings.restricted;
    },
    networksToShow: function networksToShow() {
      var _context;

      return (0, _filter.default)(_context = this.networks).call(_context, function (net) {
        return !net.hidden;
      });
    }
  },
  created: function created() {
    var _this = this;

    netProv.on('networks', function (networks) {
      _this.provided_networks = networks;
    });
  },
  methods: {
    clickAddNetwork: function clickAddNetwork() {
      var nick = 'Guest' + Math.floor(Math.random() * 100);
      var network = this.$state.getNetworkFromAddress('');

      if (typeof network === 'undefined') {
        network = this.$state.addNetwork('Network', nick, {});
      }

      network.showServerBuffer('settings');
    },
    clickAppSettings: function clickAppSettings() {
      this.$state.$emit('active.component.toggle', _AppSettings.default);
    },
    hideStatebrowser: function hideStatebrowser() {
      this.$state.$emit('statebrowser.hide');
    },
    connectProvidedNetwork: function connectProvidedNetwork(pNet) {
      var net = this.$state.addNetwork(pNet.name, pNet.nick, {
        server: pNet.server,
        port: pNet.port,
        tls: pNet.tls,
        password: pNet.password
      });
      net.ircClient.connect();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["StateBrowser"]
window._kiwi_exports.components.StateBrowser = exports.default ? exports.default : exports;
