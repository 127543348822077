"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var Colours = _interopRequireWildcard(require("@/helpers/Colours"));

var _default = {
  props: ['ircinput'],
  data: function data() {
    return {};
  },
  methods: {
    onColourClick: function onColourClick(event) {
      var colour = window.getComputedStyle(event.target, null).getPropertyValue('background-color'); // Convert rgb(x,x,x) to its hex form

      var m = colour.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

      if (m) {
        var rgbObj = {
          r: (0, _parseInt2.default)(m[1], 10),
          g: (0, _parseInt2.default)(m[2], 10),
          b: (0, _parseInt2.default)(m[3], 10)
        };
        colour = Colours.rgb2hex(rgbObj);
      }

      var code = event.target.dataset.code;
      this.ircinput.setColour(code, colour);
    },
    onResetClick: function onResetClick() {
      this.ircinput.resetStyles();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["inputtools"]) window._kiwi_exports["components"]["inputtools"] = {};
window._kiwi_exports["components"]["inputtools"]["TextStyle"]
window._kiwi_exports.components.inputtools.TextStyle = exports.default ? exports.default : exports;
