"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _lodash = require("lodash");

var _state = _interopRequireDefault(require("@/libs/state"));

var _default = render;
exports.default = _default;

function render(blocks, renderEmoticons) {
  var state = (0, _state.default)();
  var emojiLocation = state.setting('emojiLocation');
  var showEmoticons = typeof renderEmoticons === 'undefined' ? state.setting('buffers.show_emoticons') : !!renderEmoticons;
  var retHtml = (0, _reduce.default)(blocks).call(blocks, function (html, block, i) {
    var _context;

    // a
    var style = '';
    var classes = '';
    (0, _forEach.default)(_context = (0, _keys.default)(block.styles)).call(_context, function (s) {
      if (s === 'underline') {
        style += 'text-decoration:underline;';
      } else if (s === 'bold') {
        style += 'font-weight:bold;';
      } else if (s === 'italic') {
        style += 'font-style:italic;';
      } else if (s === 'quote') {
        classes += 'kiwi-formatting-extras-quote ';
      } else if (s === 'block') {
        classes += 'kiwi-formatting-extras-block ';
      } else if (s === 'color') {
        classes += "irc-fg-colour-".concat(block.styles[s], " ");
      } else if (s === 'background') {
        classes += "irc-bg-colour-".concat(block.styles[s], " ");
      }
    });
    var content;

    switch (block.type) {
      case 'url':
        content = linkifyUrl(block, {
          addHandle: state.setting('buffers.show_link_previews'),
          handleClass: 'fa fa-share-square u-link kiwi-messagelist-message-linkhandle'
        });
        break;

      case 'user':
        content = linkifyUser(block);
        break;

      case 'channel':
        content = linkifyChannel(block);
        break;

      case 'emoji':
        content = addEmoji(block, blocks.length === 1, emojiLocation, showEmoticons);
        break;

      default:
        content = (0, _lodash.escape)(block.content);
    }

    return html + buildSpan(content, classes, style);
  }, '');
  return "".concat(retHtml);
}

function linkifyUrl(block, _opts) {
  var _context2;

  var opts = _opts || {};
  var nice = ''; // Shorten the displayed URL if it's going to be too long

  if (block.content.length > 100) {
    nice = (0, _lodash.escape)(block.content.substr(0, 100)) + '...';
  } else {
    nice = (0, _lodash.escape)(block.content);
  } // Make the link clickable


  var href = block.meta.url.replace(/"/g, '%22');
  var out = (0, _concat.default)(_context2 = "<a target=\"_blank\" href=\"".concat(href, "\" rel=\"noopener noreferrer\">")).call(_context2, nice, "</a>");

  if (opts.addHandle) {
    var _context3, _context4;

    var cssClass = opts.handleClass || '';
    var content = opts.handleContent || '';
    out += (0, _concat.default)(_context3 = (0, _concat.default)(_context4 = "<a data-url=\"".concat((0, _lodash.escape)(block.meta.url), "\" class=\"")).call(_context4, cssClass, "\">")).call(_context3, content, "</a>");
  }

  return out;
}

function linkifyUser(block) {
  var escaped = (0, _lodash.escape)(block.meta.user);
  var colour = block.meta.colour;
  return "<a class=\"kiwi-nick\" data-nick=\"".concat(escaped, "\"") + (colour ? " style=\"color:".concat(colour, "\"") : '') + ">".concat(escaped, "</a>");
}

function linkifyChannel(block) {
  var _context5;

  return (0, _concat.default)(_context5 = "<a class=\"u-link kiwi-channel\" data-channel-name=\"".concat((0, _lodash.escape)(block.meta.channel), "\">")).call(_context5, (0, _lodash.escape)(block.content), "</a>");
}

function addEmoji(block, isSingle, emojiLocation, showEmoticons) {
  var _context6, _context7, _context8, _context9;

  if (!showEmoticons) {
    return block.content;
  }

  var emoji = block.meta.emoji;
  var classes = 'kiwi-messagelist-emoji' + (isSingle ? ' kiwi-messagelist-emoji--single' : '');
  var src = (0, _concat.default)(_context6 = "".concat(emojiLocation)).call(_context6, emoji, ".png");
  return (0, _concat.default)(_context7 = (0, _concat.default)(_context8 = (0, _concat.default)(_context9 = "<img class=\"".concat(classes, "\" src=\"")).call(_context9, src, "\" alt=\"")).call(_context8, (0, _lodash.escape)(block.content), "\" title=\"")).call(_context7, (0, _lodash.escape)(block.content), "\" />");
}

function buildSpan(content, classes, style) {
  if (style === '' && classes === '') {
    return content;
  }

  return '<span' + (style !== '' ? " style=\"".concat(style, "\"") : '') + (classes !== '' ? " class=\"".concat(classes, "\"") : '') + ">".concat(content, "</span>");
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
if(!window._kiwi_exports["libs"]["renderers"]) window._kiwi_exports["libs"]["renderers"] = {};
window._kiwi_exports["libs"]["renderers"]["Html"]
window._kiwi_exports.libs.renderers.Html = exports.default ? exports.default : exports;
