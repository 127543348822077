"use strict";
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var methods = {
  props: {},
  status: function status(props) {
    // let props = this.props;
    if (!props.user || !props.buffer) {
      return '';
    }

    return props.user.typingStatus(props.buffer.name).status;
  }
};
var _default = {
  props: {
    user: Object,
    buffer: Object
  },
  m: methods
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["TypingStatusIndicator"]
window._kiwi_exports.components.TypingStatusIndicator = exports.default ? exports.default : exports;
