"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var Vue = require('vue');

var _default = Vue.component('input-prompt', {
  props: ['label', 'hideCancel', 'noprompt', 'block'],
  data: function data() {
    return {
      value: '',
      state: 'pre'
    };
  },
  created: function created() {
    if (this.noprompt) {
      this.prompt();
    }
  },
  methods: {
    prompt: function prompt() {
      var _this = this;

      this.state = 'prompt';
      this.$nextTick(function () {
        _this.$el.querySelector('input').focus();
      });
    },
    complete: function complete() {
      this.$emit('submit', this.value);
      this.state = 'pre';
    },
    cancel: function cancel() {
      this.$emit('cancel');
      this.state = 'pre';
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["InputPrompt"]
window._kiwi_exports.components.utils.InputPrompt = exports.default ? exports.default : exports;
