"use strict";
'kiwi public'; // Do channels support half-op mode

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.supportsHalfOp = supportsHalfOp;
exports.isAChannelModeAdmin = isAChannelModeAdmin;
exports.isQChannelModeOwner = isQChannelModeOwner;
exports.extbanAccount = extbanAccount;

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

function supportsHalfOp(network) {
  var ircdType = network.ircd.toLowerCase();
  var notSupported = ['ircd-seven', 'charybdis'];

  for (var i = 0; i < notSupported.length; i++) {
    if ((0, _indexOf.default)(ircdType).call(ircdType, notSupported[i]) > -1) {
      return false;
    }
  }

  return true;
} // Does channel mode +a = admin


function isAChannelModeAdmin(network) {
  var ircdType = network.ircd.toLowerCase();
  var notAdmin = ['ircd-seven', 'charybdis'];

  for (var i = 0; i < notAdmin.length; i++) {
    if ((0, _indexOf.default)(ircdType).call(ircdType, notAdmin[i]) > -1) {
      return false;
    }
  }

  return true;
} // Does channel mode +q = owner


function isQChannelModeOwner(network) {
  var ircdType = network.ircd.toLowerCase();
  var notOwner = ['ircd-seven', 'charybdis'];

  for (var i = 0; i < notOwner.length; i++) {
    if ((0, _indexOf.default)(ircdType).call(ircdType, notOwner[i]) > -1) {
      return false;
    }
  }

  return true;
}

function extbanAccount(network) {
  // Eg. InspIRCd-2.0
  // Eg. UnrealIRCd-4.0.17
  // Eg. ircd-seven-1.1.7
  // Eg. u2.10.12.10+snircd(1.3.4a)
  var ircdType = network.ircd.toLowerCase(); // Eg. ~,qjncrRa
  // Eg. ,qjncrRa

  var extban = network.ircClient.network.supports('EXTBAN') || '';

  if (!extban) {
    return '';
  }

  var prefix = extban.split(',')[0];
  var type = 'a'; // https://docs.inspircd.org/3/modules/services_account/#extended-bans

  if ((0, _indexOf.default)(ircdType).call(ircdType, 'inspircd') > -1) {
    type = 'R';
  }

  return prefix + type;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["helpers"]) window._kiwi_exports["helpers"] = {};
window._kiwi_exports["helpers"]["IrcdDiffs"]
window._kiwi_exports.helpers.IrcdDiffs = exports.default ? exports.default : exports;
