"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = parse;
exports.createNewBlock = createNewBlock;

var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _create = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/create"));

var tokens = (0, _create.default)(null);
/**
 * Token functions may return:
 * null - move forward in the input by the length of the token
 * -1 - do not treat this character as a token and continue as normal content
 * 0+ - move to this point in the input
 */

/* eslint-disable dot-notation */

tokens['_'] = {
  token: '_',
  extra: true,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    var _context;

    if (openToks[this.token]) {
      delete block.styles.underline;
      openToks[this.token] = null;
      prevBlock.content += this.token;
      return null;
    } // If this style is alrady open by something else, ignore it


    if (block.styles.underline === true) {
      return -1;
    } // Underscores may be part of a word or URL so consider it an opening


    if (pos > 0 && inp[pos - 1] !== ' ') {
      return -1;
    } // token if it's on it's own
    // Only underline if we have a closing _ further on


    if ((0, _indexOf.default)(_context = inp.substr(pos + 1)).call(_context, this.token) === -1) {
      return -1;
    }

    openToks[this.token] = true;
    block.styles.underline = true;
    block.content += this.token;
    return null;
  }
};
tokens['*'] = {
  token: '*',
  extra: true,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    if (openToks[this.token]) {
      delete block.styles.bold;
      openToks[this.token] = null;
      prevBlock.content += this.token;
      return null;
    } // If this style is alrady open by something else, ignore it


    if (block.styles.bold === true) {
      return -1;
    } // Ignore is after a : character. :* is usually a kiss emoji


    if (inp[pos - 1] === ':') {
      return -1;
    } // * may be part of a word (ie. pasting code) or URL so only start bolding if * is after a
    // space


    if (pos > 0 && inp[pos - 1] !== ' ') {
      return -1;
    } // Only style if:
    //     * we have a closing * further on
    //     * the * further on has a space after it or is the last character


    var remainingText = inp.substr(pos + 1);
    var nextPos = (0, _indexOf.default)(remainingText).call(remainingText, this.token);

    if (nextPos === -1 || nextPos < remainingText.length - 1 && remainingText[nextPos + 1] !== ' ') {
      return -1;
    }

    openToks[this.token] = true;
    block.styles.bold = true;
    block.content += this.token;
    return null;
  }
};
tokens['**'] = {
  token: '**',
  extra: true,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    var _context2;

    if (openToks[this.token]) {
      delete block.styles.italic;
      openToks[this.token] = null;
      prevBlock.content += this.token;
      return null;
    } // If this style is alrady open by something else, ignore it


    if (block.styles.italic === true) {
      return -1;
    } // Only style if we have a closing ** further on


    if ((0, _indexOf.default)(_context2 = inp.substr(pos + 1)).call(_context2, this.token) === -1) {
      return -1;
    }

    openToks[this.token] = true;
    block.styles.italic = true;
    block.content += this.token;
    return null;
  }
};
tokens['`'] = {
  token: '`',
  extra: true,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    if (openToks[this.token]) {
      delete block.styles.quote;
      openToks[this.token] = null;
      prevBlock.content += this.token;
      return null;
    } // Backticks may be part of a word or NICK so only consider it a codeblock if
    // it's at the start of a scentence or comes after a space


    if (pos > 0 && inp[pos - 1] !== ' ') {
      return -1;
    } // Find the closing backtick


    var str = inp.substr(pos + 1);
    var endPos = (0, _indexOf.default)(str).call(str, this.token); // If we don't have a closing backtick further on, don't take this as an opening backtick

    if (endPos === -1) {
      return -1;
    } // Only consider the found closing backtick as closing if it comes before a space or is at
    // the end of the text. If it's in the middle of text then it's highly lickely part of that
    // text and not actually closing. eg. `hello and wel`come


    if (str[endPos + 1] && str[endPos + 1] !== ' ') {
      return -1;
    } // No styling should appear in this codeblock. Add all the content we can
    // before jumping the position forward in the input


    openToks[this.token] = true;
    block.styles.quote = true;
    block.content += this.token + str.substr(0, endPos);
    block.containsContent = true; // The + 1 because:
    // We added 2 ` characters, but we want the last ` character to call this
    // fn again so it can be closed.

    return pos + endPos + 1;
  }
}; // Bold

tokens['\x02'] = {
  token: '\x02',
  extra: false,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    if (openToks[this.token]) {
      delete block.styles.bold;
      openToks[this.token] = null;
    } else {
      openToks[this.token] = true;
      block.styles.bold = true;
    }

    return null;
  }
}; // Italic

tokens['\x1D'] = {
  token: '\x1D',
  extra: false,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    if (openToks[this.token]) {
      delete block.styles.italic;
      openToks[this.token] = null;
    } else {
      openToks[this.token] = true;
      block.styles.italic = true;
    }

    return null;
  }
}; // Underline

tokens['\x1F'] = {
  token: '\x1F',
  extra: false,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    if (openToks[this.token]) {
      delete block.styles.underline;
      openToks[this.token] = null;
    } else {
      openToks[this.token] = true;
      block.styles.underline = true;
    }

    return null;
  }
}; // Clear all styles

tokens['\x0F'] = {
  token: '\x0F',
  extra: false,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    var _context3, _context4;

    (0, _forEach.default)(_context3 = (0, _keys.default)(block.styles)).call(_context3, function (k) {
      return delete block.styles[k];
    });
    (0, _forEach.default)(_context4 = (0, _keys.default)(openToks)).call(_context4, function (k) {
      return delete openToks[k];
    });
    return null;
  }
}; // Colours

tokens['\x03'] = {
  token: '\x03',
  extra: false,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    var colours = {
      0: 'white',
      1: 'black',
      2: 'blue',
      3: 'green',
      4: 'light-red',
      5: 'brown',
      6: 'purple',
      7: 'orange',
      8: 'yellow',
      9: 'light-green',
      10: 'cyan',
      11: 'light-cyan',
      12: 'light-blue',
      13: 'pink',
      14: 'grey',
      15: 'light-grey'
    };
    var colourMatchRegexp = /^\x03(([0-9][0-9]?)(,([0-9][0-9]?))?)/;
    var match = colourMatchRegexp.exec(inp.substr(pos, 6));

    if (match) {
      // fg colour = 2, bg colour = 4
      var fgColour = colours[(0, _parseInt2.default)(match[2], 10)];
      var bgColour = colours[(0, _parseInt2.default)(match[4], 10)];

      if (typeof fgColour !== 'undefined') {
        block.styles.color = fgColour;
      }

      if (typeof bgColour !== 'undefined') {
        block.styles.background = bgColour;
      }

      return pos + match[0].length;
    } // 03 without a colour = delete any existing colour


    delete block.styles.color;
    delete block.styles.background;
    return null;
  }
}; // Reverse (unimplemented)

tokens['\x16'] = {
  token: '\x16',
  extra: false,
  fn: function parseToken(inp, pos, block, prevBlock, openToks) {
    return null;
  }
};

function parse(inp, _opts) {
  var opts = _opts || {};
  var block = createNewBlock();
  var blocks = [block];
  var openTokens = (0, _create.default)(null);
  var pos = 0;
  var len = inp.length;

  while (pos < len) {
    var tok = findTokenAtPosition();

    if (!tok || !opts.extras && tok.extra) {
      block.content += inp[pos];
      block.containsContent = true;
      pos++;
      continue;
    } // Current character is a token of some sort


    var newBlock = null; // Consecutive tokens can stack up styles into the same blocks. A consecutive
    // token means that the block before it will not have any content yet

    if (block.containsContent) {
      newBlock = createNewBlock();
    } else {
      newBlock = block;
    }

    (0, _assign.default)(newBlock.styles, block.styles);
    var newPos = tok.fn(inp, pos, newBlock, block, openTokens);

    if (newPos === -1) {
      // The token invalidated itself so treat it as content
      block.content += tok.token;
      block.containsContent = true;
      pos += tok.token.length;
      continue;
    }

    block = newBlock; // This block may have already been pushed if this is a consecutive token

    if (blocks[blocks.length - 1] !== newBlock) {
      blocks.push(newBlock);
    }

    if (typeof newPos === 'number') {
      pos = newPos;
    } else {
      pos += tok.token.length;
    }
  }

  return blocks;

  function findTokenAtPosition() {
    var tok = tokens[inp.substr(pos, 2)] || tokens[inp[pos]];
    return tok;
  }
}

function createNewBlock() {
  var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var styles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'text';
  var meta = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var newBlock = {
    styles: styles,
    content: content,
    containsContent: !!content,
    type: type,
    meta: meta
  };
  return newBlock;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["MessageFormatter"]
window._kiwi_exports.libs.MessageFormatter = exports.default ? exports.default : exports;
