"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var methods = {
  props: {},
  avatar: function avatar(props) {
    // let props = this.props;
    return props.message && props.message.avatar || props.user && props.user.avatar;
  },
  firstNickLetter: function firstNickLetter(props) {
    // let props = this.props;
    return (props.message && props.message.nick || props.user && props.user.nick || '')[0];
  },
  hasAvatar: function hasAvatar(props) {
    // let props = this.props;
    return !!(props.user.avatar && (props.user.avatar.small || props.user.avatar.large));
  },
  avatarStyle: function avatarStyle(props) {
    // let props = this.props;
    var style = {};

    if (this.hasAvatar(props)) {
      var url = props.size === 'small' && this.avatar(props).small ? this.avatar(props).small : this.avatar(props).large;
      style['background-image'] = "url(\"".concat(url, "\")");
    } else {
      style['background-color'] = "".concat(this.colour(props));
    }

    return style;
  },
  colour: function colour(props) {
    // let props = this.props;
    var user = props.message && props.message.user || props.user;
    return user.getColour();
  }
};
var _default = {
  props: {
    message: Object,
    user: Object,
    size: String
  },
  m: methods
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Avatar"]
window._kiwi_exports.components.Avatar = exports.default ? exports.default : exports;
