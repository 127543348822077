"use strict";
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _Avatar = _interopRequireDefault(require("./Avatar"));

// This component simply wraps the Avatar component so that plugins may override this messagelist
// avatar separately than the nicklist avatar
var _default = {
  c: {
    Avatar: _Avatar.default
  },
  props: ['message', 'user']
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MessageListAvatar"]
window._kiwi_exports.components.MessageListAvatar = exports.default ? exports.default : exports;
