"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var _default = {
  props: ['ircinput'],
  computed: {
    emojis: function emojis() {
      var list = {};
      var available = this.$state.setting('emojis');

      _lodash.default.each(available, function (code, ascii) {
        list[code] = ascii;
      });

      return list;
    },
    location: function location() {
      return this.$state.setting('emojiLocation');
    }
  },
  methods: {
    onImgClick: function onImgClick(event) {
      var url = window.getComputedStyle(event.target, null).getPropertyValue('background-image');
      url = Misc.extractURL(url);
      var code = event.target.dataset.code;
      this.ircinput.addImg(code, url);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["inputtools"]) window._kiwi_exports["components"]["inputtools"] = {};
window._kiwi_exports["components"]["inputtools"]["Emoji"]
window._kiwi_exports.components.inputtools.Emoji = exports.default ? exports.default : exports;
