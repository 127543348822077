"use strict";
'kiwi public';
/** @module */

/**
 * Adds the +typing IRCv3 spec to irc-framework
 */

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = typingMiddleware;

var _now = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/date/now"));

var _create = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/create"));

function typingMiddleware() {
  return function middleware(client, rawEvents, parsedEvents) {
    addFunctionsToClient(client);
    rawEvents.use(theMiddleware);
  };

  function theMiddleware(command, message, rawLine, client, next) {
    if (!(command === 'TAGMSG' && message.tags['+typing']) && !(command === 'PRIVMSG' && message.nick)) {
      next();
      return;
    } // if we are params[0] then the target is the sender (direct message)


    var target = message.params[0].toLowerCase() === client.user.nick.toLowerCase() ? message.nick : message.params[0]; // if its a privmsg without typing tag emit done

    var status = message.tags['+typing'] || 'done';
    client.emit('typing', {
      target: target,
      nick: message.nick,
      ident: message.ident,
      hostname: message.hostname,
      status: status
    });
    next();
  }
}

function addFunctionsToClient(client) {
  var typing = client.typing = {};
  var activeTyping = (0, _create.default)(null);

  function isEnabled() {
    return client.network.cap.isEnabled('message-tags');
  }

  typing.start = function start(target) {
    if (!isEnabled()) {
      return;
    }

    var lastSentStatus = activeTyping[target.toLowerCase()];

    if (lastSentStatus && lastSentStatus > (0, _now.default)() - 3000) {
      return;
    }

    activeTyping[target.toLowerCase()] = (0, _now.default)();
    var message = new client.Message('TAGMSG', target);
    message.tags['+typing'] = 'active';
    client.raw(message);
  };

  typing.pause = function pause(target) {
    if (!isEnabled()) {
      return;
    }

    var message = new client.Message('TAGMSG', target);
    message.tags['+typing'] = 'paused';
    client.raw(message);
  };

  typing.stop = function stop(target) {
    if (!isEnabled()) {
      return;
    }

    var message = new client.Message('TAGMSG', target);
    message.tags['+typing'] = 'done';
    client.raw(message);
    delete activeTyping[target.toLowerCase()];
  };
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["TypingMiddleware"]
window._kiwi_exports.libs.TypingMiddleware = exports.default ? exports.default : exports;
