"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var _CommonLayout = _interopRequireDefault(require("./CommonLayout"));

var _default = {
  components: {
    StartupLayout: _CommonLayout.default
  },
  data: function data() {
    return {
      network: null,
      network_extras: null,
      username: '',
      password: '',
      znc_network: '',
      showNetwork: true,
      showPass: true,
      showUser: true,
      show_password_box: false
    };
  },
  computed: {
    greetingText: function greetingText() {
      var greeting = this.$state.settings.startupOptions.greetingText;
      return typeof greeting === 'string' ? greeting : this.$t('start_greeting');
    },
    buttonText: function buttonText() {
      var greeting = this.$state.settings.startupOptions.buttonText;
      return typeof greeting === 'string' ? greeting : this.$t('start_button');
    },
    readyToStart: function readyToStart() {
      return this.username && (this.password || this.showPass === false);
    },
    infoContent: function infoContent() {
      return this.$state.settings.startupOptions.infoContent || '';
    }
  },
  created: function created() {
    var options = this.$state.settings.startupOptions;
    this.username = options.username || '';
    this.password = options.password || '';
    this.znc_network = window.location.hash.substr(1) || options.network || '';
    this.showNetwork = typeof options.showNetwork === 'boolean' ? options.showNetwork : true;
    this.showUser = typeof options.showUser === 'boolean' ? options.showUser : true;
    this.showPass = typeof options.showPass === 'boolean' ? options.showPass : true;

    if (options.autoConnect && this.username && this.password) {
      this.startUp();
    }
  },
  methods: {
    readableStateError: function readableStateError(err) {
      return Misc.networkErrorMessage(err);
    },
    formSubmit: function formSubmit() {
      if (this.readyToStart) {
        this.startUp();
      }
    },
    addNetwork: function addNetwork(netName) {
      var options = this.$state.settings.startupOptions;
      var password = this.username;

      if (netName) {
        password += '/' + netName;
      }

      password += ':' + this.password;
      var net = this.$state.addNetwork(netName, 'ZNC', {
        server: (0, _trim.default)(_lodash.default).call(_lodash.default, options.server),
        port: options.port,
        tls: options.tls,
        password: password
      });
      return net;
    },
    startUp: function startUp() {
      var _this = this;

      if (this.network) {
        this.$state.removeNetwork(this.network.id);
      }

      var netList = _lodash.default.compact(this.znc_network.split(','));

      if (netList.length === 0) {
        netList.push('');
      } // add our first network and make sure we can connect
      // before trying to add other networks.


      var net = this.network = this.addNetwork(netList.shift());
      this.network_extras = netList;

      var onRegistered = function onRegistered() {
        var _context;

        _this.$state.setActiveBuffer(net.id, net.serverBuffer().name);

        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
        (0, _forEach.default)(_context = _this.network_extras).call(_context, function (netName, idx) {
          var extraNet = _this.addNetwork((0, _trim.default)(_lodash.default).call(_lodash.default, netName));

          extraNet.ircClient.connect();
        });

        _this.$refs.layout.close();
      };

      var onClosed = function onClosed() {
        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
      };

      net.ircClient.once('registered', onRegistered);
      net.ircClient.once('close', onClosed);
      net.ircClient.connect();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["startups"]) window._kiwi_exports["components"]["startups"] = {};
window._kiwi_exports["components"]["startups"]["ZncLogin"]
window._kiwi_exports.components.startups.ZncLogin = exports.default ? exports.default : exports;
