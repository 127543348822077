"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var defaultLogger = makeLogger();
var _default = defaultLogger;
exports.default = _default;

function makeLogger(label, rootLogger) {
  function logger() {
    logger.info.apply(logger, arguments);
  }

  logger.LEVEL_DEBUG = 2;
  logger.LEVEL_INFO = 1;
  logger.LEVEL_ERROR = 0;
  logger.level = logger.LEVEL_ERROR;

  function logLevel(compareLevel) {
    var l = rootLogger || logger;
    return l.level >= compareLevel;
  }

  logger.debug = function logDebug() {
    if (logLevel(logger.LEVEL_DEBUG)) {
      var _window$console;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      if (label) {
        var _context;

        args[0] = (0, _concat.default)(_context = "[".concat(label, "] DEBUG ")).call(_context, args[0]);
      }

      (_window$console = window.console).log.apply(_window$console, args);
    }
  };

  logger.info = function logInfo() {
    if (logLevel(logger.LEVEL_INFO)) {
      var _window$console2;

      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      if (label) {
        var _context2;

        args[0] = (0, _concat.default)(_context2 = "[".concat(label, "] INFO ")).call(_context2, args[0]);
      }

      (_window$console2 = window.console).log.apply(_window$console2, args);
    }
  };

  logger.error = function logError() {
    if (logLevel(logger.LEVEL_ERROR)) {
      var _window$console3;

      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      if (label) {
        var _context3;

        args[0] = (0, _concat.default)(_context3 = "[".concat(label, "] ERROR ")).call(_context3, args[0]);
      }

      (_window$console3 = window.console).error.apply(_window$console3, args);
    }
  };

  logger.assert = function assert(condition) {
    var _context4;

    if (condition) {
      return;
    }

    for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
      args[_key4 - 1] = arguments[_key4];
    }

    this.error.apply(this, (0, _concat.default)(_context4 = ['Assertion failed.']).call(_context4, args));
  };

  logger.setLevel = function setLevel(newLevel) {
    logger.level = newLevel;
  };

  logger.namespace = function namespace(newLabel) {
    var l = newLabel;

    if (label) {
      var _context5;

      l = (0, _concat.default)(_context5 = "[".concat(label, "][")).call(_context5, l, "]");
    }

    return makeLogger(l, logger);
  };

  return logger;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["Logger"]
window._kiwi_exports.libs.Logger = exports.default ? exports.default : exports;
