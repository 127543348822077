var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"kiwi-messagelist-message kiwi-messagelist-message--modern",class:[
        _vm.props.m().isRepeat() ?
            'kiwi-messagelist-message--authorrepeat' :
            'kiwi-messagelist-message--authorfirst',
        'kiwi-messagelist-message-' + _vm.props.message.type,
        _vm.props.message.type_extra ?
            'kiwi-messagelist-message-' + _vm.props.message.type + '-' + _vm.props.message.type_extra :
            '',
        _vm.props.ml.isMessageHighlight(_vm.props.message) ?
            'kiwi-messagelist-message--highlight' :
            '',
        _vm.props.ml.isHoveringOverMessage(_vm.props.message) ?
            'kiwi-messagelist-message--hover' :
            '',
        _vm.props.ml.buffer.last_read && _vm.props.message.time > _vm.props.ml.buffer.last_read ?
            'kiwi-messagelist-message--unread' :
            '',
        _vm.props.message.nick.toLowerCase() === _vm.props.ml.ourNick.toLowerCase() ?
            'kiwi-messagelist-message--own' :
            '',
        _vm.props.ml.message_info_open === _vm.props.message ?
            'kiwi-messagelist-message--info-open' :
            '',
        _vm.props.ml.message_info_open && _vm.props.ml.message_info_open !== _vm.props.message ?
            'kiwi-messagelist-message--blur' :
            '',
        (_vm.props.message.user && _vm.props.m().userMode(_vm.props.message.user)) ?
            'kiwi-messagelist-message--user-mode-' + _vm.props.m().userMode(_vm.props.message.user) :
            '',
        _vm.data.staticClass ],attrs:{"data-message-id":_vm.props.message.id,"data-nick":(_vm.props.message.nick||'').toLowerCase()},on:{"click":function($event){return _vm.props.ml.onMessageClick($event, _vm.props.message, true)},"dblclick":function($event){return _vm.props.ml.onMessageDblClick($event, _vm.props.message)}}},[_c('div',{staticClass:"kiwi-messagelist-modern-left"},[(_vm.props.m().isMessage(_vm.props.message) && _vm.props.m().displayAvatar(_vm.props.message))?_c(_vm.injections.components.MessageAvatar,{tag:"component",attrs:{"message":_vm.props.message,"data-nick":_vm.props.message.nick,"user":_vm.props.message.user}}):_vm._e(),(_vm.props.message.user && !_vm.props.m().isRepeat())?_c(_vm.injections.components.AwayStatusIndicator,{tag:"component",staticClass:"kiwi-messagelist-awaystatus",attrs:{"network":_vm.props.m().getNetwork(),"user":_vm.props.message.user,"toggle":false}}):_vm._e()],1),_c('div',{staticClass:"kiwi-messagelist-modern-right"},[_c('div',{staticClass:"kiwi-messagelist-top"},[(_vm.props.message.nick)?_c('a',{class:[
                    'kiwi-messagelist-nick',
                    _vm.props.message.user && _vm.props.m().userMode(_vm.props.message.user) ?
                        'kiwi-messagelist-nick--mode-'+_vm.props.m().userMode(_vm.props.message.user) :
                        ''
                ],style:({ 'color': _vm.props.ml.userColour(_vm.props.message.user) }),attrs:{"data-nick":(_vm.props.message.nick).toLowerCase()},on:{"mouseover":function($event){_vm.props.ml.hover_nick=_vm.props.message.nick.toLowerCase();},"mouseout":function($event){_vm.props.ml.hover_nick='';}}},[_c('span',{staticClass:"kiwi-messagelist-nick-prefix"},[_vm._v(_vm._s(_vm.props.message.user ? _vm.props.m().userModePrefix(_vm.props.message.user) : ''))]),_vm._v(_vm._s(_vm.props.message.nick)+" ")]):_vm._e(),(_vm.props.m().showRealName())?_c('div',{staticClass:"kiwi-messagelist-realname",on:{"click":function($event){return _vm.props.ml.openUserBox(_vm.props.message.nick)},"mouseover":function($event){_vm.props.ml.hover_nick=_vm.props.message.nick.toLowerCase();},"mouseout":function($event){_vm.props.ml.hover_nick='';}}},[_vm._v(" "+_vm._s(_vm.props.message.user.realname)+" ")]):_vm._e(),(_vm.props.m().isMessage(_vm.props.message) &&
                    _vm.props.ml.bufferSetting('show_timestamps'))?_c('div',{staticClass:"kiwi-messagelist-time",attrs:{"title":_vm.props.ml.formatTimeFull(_vm.props.message.time)}},[_vm._v(" "+_vm._s(_vm.props.ml.formatTime(_vm.props.message.time))+" ")]):_vm._e()]),(_vm.props.message.bodyTemplate && _vm.props.message.bodyTemplate.$el)?_c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:(_vm.props.message.bodyTemplate.$el),expression:"props.message.bodyTemplate.$el"}],staticClass:"kiwi-messagelist-body"}):_c('div',{staticClass:"kiwi-messagelist-body",domProps:{"innerHTML":_vm._s(_vm.props.ml.formatMessage(_vm.props.message))}}),(_vm.props.ml.message_info_open===_vm.props.message)?_c(_vm.injections.components.MessageInfo,{tag:"component",attrs:{"message":_vm.props.message,"buffer":_vm.props.ml.buffer},on:{"close":function($event){return _vm.props.ml.toggleMessageInfo()}}}):_vm._e(),(_vm.props.message.embed.payload)?_c('div',[_c(_vm.injections.components.MediaViewer,{tag:"component",attrs:{"url":_vm.props.message.embed.payload,"show-pin":true},on:{"close":function($event){_vm.props.message.embed.payload = ''},"pin":function($event){return _vm.props.ml.openEmbedInPreview(_vm.props.message)}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }