"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var Vue = require('vue');

Vue.component('tabbed-tab', {
  props: {
    header: {
      status: String
    },
    focus: {
      status: Boolean
    },
    name: {
      status: String
    }
  },
  data: function data() {
    return {
      active: false
    };
  },
  template: '<div v-if="active" class="u-tabbed-content"><slot></slot></div>'
});

var _default = Vue.component('tabbed-view', {
  data: function data() {
    return {
      // We increment this when we need to re-render the tabs.
      // Vue doesn't pick up on the $children changes all the time so we handle
      // it ourselves.
      a: 1,
      prefixID: Math.floor(Math.random() * 100000).toString(36)
    };
  },
  computed: {
    tabs: function computedtabs() {
      return this.$children;
    }
  },
  mounted: function mounted() {
    this.setActiveCheck();
  },
  methods: {
    getActive: function getActive() {
      var _context;

      var foundChild = null;
      (0, _forEach.default)(_context = this.$children).call(_context, function (child) {
        if (child.active) {
          foundChild = child;
        }
      });
      return foundChild;
    },
    setActive: function setActive(c) {
      var _context2;

      (0, _forEach.default)(_context2 = this.$children).call(_context2, function (child) {
        if (child !== c) {
          child.active = false;
        }
      });
      c.active = true; // Without this, vue doesnt update itself with the new $children :(

      this.a++;
      this.$emit('changed', c.name);
    },
    setActiveByName: function setActiveByName(name) {
      var _context3,
          _this = this;

      (0, _forEach.default)(_context3 = this.$children).call(_context3, function (child) {
        if (child.name === name) {
          _this.setActive(child);
        }
      });
    },
    setActiveCheck: function setActiveCheck() {
      var _context4,
          _this2 = this;

      (0, _forEach.default)(_context4 = this.$children).call(_context4, function (t) {
        if (t.focus) {
          _this2.setActive(t);
        }
      });
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["TabbedView"]
window._kiwi_exports.components.utils.TabbedView = exports.default ? exports.default : exports;
