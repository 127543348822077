"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var Vue = require('vue');

var _default = Vue.component('input-text', {
  props: ['value', 'label', 'type', 'showPlainText'],
  data: function data() {
    return {
      plainTextEnabled: false,
      inputIdCache: ''
    };
  },
  computed: {
    inputId: function inputId() {
      if (!this.inputIdCache) {
        // eslint-disable-next-line
        this.inputIdCache = 'inp_' + Math.floor(Math.random() * 1e17).toString(36);
      }

      return this.inputIdCache;
    },
    currentValue: {
      get: function getCurrentValue() {
        return this.value;
      },
      set: function setCurrentValue(newVal) {
        this.$emit('input', newVal);
      }
    }
  },
  methods: {
    updateValue: function updateValue(newValue) {
      this.$emit('input', newValue);
    },
    isEdgeBrowser: function isEdgeBrowser() {
      var _context;

      return (0, _indexOf.default)(_context = navigator.appVersion).call(_context, 'Edge') > -1;
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["InputText"]
window._kiwi_exports.components.utils.InputText = exports.default ? exports.default : exports;
